import { Container, Grid, Typography } from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import CheckList from "../components/CheckList";
import EnquiryForm from "../components/EnquiryForm";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  assetFinancePageHeroContainer: {
    backgroundColor: teal[50]
  },
  assetFinancePageTitle: {
    marginBottom: theme.spacing(2)
  }
}));

const AssetFinancePage = () => {
  const classes = useStyles();
  const title = "Asset Finance Quote";
  const description =
    "If you are looking for assistance in financing piece of equipment, vehicles, or even software, we have a number of lenders that are willing to help.";

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Section className={classes.assetFinancePageHeroContainer}>
        <Container>
          <Grid container>
            <Grid item xs={12} lg={10}>
              <Typography
                className={classes.assetFinancePageTitle}
                variant="h1"
              >
                {title}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {description}
              </Typography>
              <CheckList
                points={[
                  "Get up to 100% of the assets value",
                  "No fees",
                  "Any item(s) considered"
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <EnquiryForm type="ASSET_FINANCE" />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default AssetFinancePage;
